<template>
	<div class="mb-5">
		<div class="row justify-content-end mb-2">
			<b-col md="4">
				<b-form-datepicker placeholder="Choose a date" local="en" v-model="from_date" />
			</b-col>
			<div md="1" class="d-flex align-items-center ml-1">to</div>
			<b-col md="4">
				<b-form-datepicker placeholder="Choose a date" local="en" v-model="to_date" @input="getData()" />
			</b-col>
		</div>
		<div style="display: flex; justify-content: end; padding: 0.5rem" v-if="this.farmId === undefined">
			<svg
				xmlns="http://www.w3.org/2000/svg"
				width="18px"
				height="18px"
				viewBox="0 0 24 24"
				fill="none"
				stroke="currentColor"
				stroke-width="2"
				stroke-linecap="round"
				stroke-linejoin="round"
				class="feather feather-user"
			>
				<path d="M20 21v-2a4 4 0 0 0-4-4H8a4 4 0 0 0-4 4v2"></path>
				<circle cx="12" cy="7" r="4"></circle>
			</svg>
			<p class="show-user">Select User</p>
			<label class="switch">
				<input class="user-check" type="checkbox" value="Farm" unchecked-value="User" @click="handleCheckboxClick" />
				<span class="slider round"></span>
			</label>
		</div>
		<div v-if="infoData && infoData.length > 0">
			<div v-for="data in infoData" :key="data.id">
				<div class="row match-height mb-1">
					<div class="col-md-2 p-0">
						<div class="card card-congratulations">
							<div class="py-2 px-1 text-center">
								<b-img
									:src="require('@/assets/images/svg/hatchery/card-top.svg')"
									class="svg-img mb-1"
									style="height: 24px; width: 24px"
								/>
								<div class="text-center">
									<h6 class="font-small-2 text-white">Poultrac</h6>
									<h4 class="mb-1 text-white w-100">
										<!-- {{ info.hatchery_name || "NA" }} -->
									</h4>
								</div>

								<div class="d-flex flex-column justify-content-between" style="align-items: center">
									<div class="d-flex justify-content-between mt-1">
										<p class="card-text">{{ data.name }}</p>
									</div>
								</div>
							</div>
						</div>
					</div>
					<div class="col-sm-3">
						<b-card class="shed">
							<div class="d-flex justify-content-between">
								<div class="d-flex justify-content-center flex-column">
									<div class="mb-1">No of Sheds : {{ data.shedCount }}</div>
									<div v-for="shed in data.shed" :key="shed.id">
										<div class="mb-1 shed-name" @click="shedInfo(shed.id)">
											{{ shed.shed_name }}
										</div>
									</div>
								</div>
							</div>
						</b-card>
					</div>
					<div class="col-lg-7 card card-statistics">
						<div class="card-header"></div>
						<div class="card-body statistics-body">
							<div class="row">
								<div class="col-12 d-flex">
									<div class="col-sm-6 col-md-4 col-xl-3 mb-2 mb-xl-0">
										<div class="media">
											<div class="media-aside mr-2 align-self-start">
												<span class="b-avatar badge-light-primary rounded-circle" style="width: 48px; height: 48px"
													><span class="b-avatar-custom"
														><b-img
															:src="require('@/assets/images/dashboard/number_of_birds.svg')"
															style="height: 48px; width: 48px"
														/> </span
													><!----></span
												>
											</div>
											<div class="media-body my-auto">
												<h4 class="font-weight-bolder mb-0">
													{{ data.totalBirds }}
												</h4>
												<p class="card-text font-small-3 mb-0">NO of Birds</p>
											</div>
										</div>
									</div>
									<div class="col-sm-6 col-md-4 col-xl-3 mb-2 mb-xl-0">
										<div class="media">
											<div class="media-aside mr-2 align-self-start">
												<span class="b-avatar badge-light-info rounded-circle" style="width: 48px; height: 48px"
													><span class="b-avatar-custom"
														><b-img
															:src="require('@/assets/images/dashboard/mortality.svg')"
															style="height: 48px; width: 48px"
														/> </span
													><!----></span
												>
											</div>
											<div class="media-body my-auto">
												<h4 class="font-weight-bolder mb-0">
													{{ data.mortalityRate }}
												</h4>
												<p class="card-text font-small-3 mb-0">Mortality Rate</p>
											</div>
										</div>
									</div>
									<div class="col-sm-6 col-md-4 col-xl-3 mb-2 mb-sm-0">
										<div class="media">
											<div class="media-aside mr-2 align-self-start">
												<span class="b-avatar badge-light-primary rounded-circle" style="width: 48px; height: 48px"
													><span class="b-avatar-custom"
														><b-img
															:src="require('@/assets/images/dashboard/egg_production.svg')"
															style="height: 48px; width: 48px"
														/> </span
													><!----></span
												>
											</div>
											<div class="media-body my-auto">
												<h4 class="font-weight-bolder mb-0">
													{{ data.eggProduction }}
												</h4>
												<p class="card-text font-small-3 mb-0">Egg Production</p>
											</div>
										</div>
									</div>
									<div class="col-sm-6 col-md-4 col-xl-3">
										<div class="media">
											<div class="media-aside mr-2 align-self-start">
												<span class="b-avatar badge-light-primary rounded-circle" style="width: 48px; height: 48px"
													><span class="b-avatar-custom"
														><b-img
															:src="require('@/assets/images/dashboard/feed_stock.svg')"
															style="height: 48px; width: 48px"
														/> </span
													><!----></span
												>
											</div>
											<div class="media-body my-auto">
												<h4 class="font-weight-bolder mb-0">
													{{ data.feedStock }}
												</h4>
												<p class="card-text font-small-3 mb-0">Feed Stock</p>
											</div>
										</div>
									</div>
								</div>
								<div class="col-12 d-flex mt-4">
									<div class="col-sm-6 col-md-4 col-xl-3 mb-2 mb-xl-0">
										<div class="media">
											<div class="media-aside mr-2 align-self-start">
												<span class="b-avatar badge-light-primary rounded-circle" style="width: 48px; height: 48px"
													><span class="b-avatar-custom"
														><b-img
															:src="require('@/assets/images/dashboard/hdep.svg')"
															style="height: 48px; width: 48px"
														/> </span
													><!----></span
												>
											</div>
											<div class="media-body my-auto">
												<h4 class="font-weight-bolder mb-0">
													{{ data.hdep }}
												</h4>
												<p class="card-text font-small-3 mb-0">HDEP %</p>
											</div>
										</div>
									</div>
									<div class="col-sm-6 col-md-4 col-xl-3 mb-2 mb-xl-0">
										<div class="media">
											<div class="media-aside mr-2 align-self-start">
												<span class="b-avatar badge-light-info rounded-circle" style="width: 48px; height: 48px"
													><span class="b-avatar-custom"
														><b-img
															:src="require('@/assets/images/dashboard/hhep.svg')"
															style="height: 48px; width: 48px"
														/> </span
													><!----></span
												>
											</div>
											<div class="media-body my-auto">
												<h4 class="font-weight-bolder mb-0">
													{{ data.hhep }}
												</h4>
												<p class="card-text font-small-3 mb-0">HHEP %</p>
											</div>
										</div>
									</div>
									<div class="col-sm-6 col-md-4 col-xl-3 mb-2 mb-sm-0">
										<div class="media">
											<div class="media-aside mr-2 align-self-start">
												<span class="b-avatar badge-light-primary rounded-circle" style="width: 48px; height: 48px"
													><span class="b-avatar-custom"
														><b-img
															:src="require('@/assets/images/dashboard/hatching_eggs.svg')"
															style="height: 48px; width: 48px"
														/> </span
													><!----></span
												>
											</div>
											<div class="media-body my-auto">
												<h4 class="font-weight-bolder mb-0">
													{{ data.hatching_egg }}
												</h4>
												<p class="card-text font-small-3 mb-0">Hatching Egg %</p>
											</div>
										</div>
									</div>
									<div class="col-sm-6 col-md-4 col-xl-3">
										<div class="media">
											<div class="media-aside mr-2 align-self-start">
												<span class="b-avatar badge-light-primary rounded-circle" style="width: 48px; height: 48px"
													><span class="b-avatar-custom"
														><b-img
															:src="require('@/assets/images/dashboard/sold_egg.svg')"
															style="height: 48px; width: 48px"
														/> </span
													><!----></span
												>
											</div>
											<div class="media-body my-auto">
												<h4 class="font-weight-bolder mb-0">
													{{ data.eggSold }}
												</h4>
												<p class="card-text font-small-3 mb-0">Sold Egg</p>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
		<div v-else>
			<b-card>
				<div class="d-flex top-header align-items-center">
					<feather-icon icon="ArrowLeftIcon" size="24" class="mr-2" @click="goBack" style="cursor: pointer" />
					<div>
						<h5>No Data Found</h5>
					</div>
				</div>
				<div class="d-flex flex-column justify-content-center align-items-center py-5">
					<b-img :src="require('@/assets/images/svg/machine/no-setter.svg')" class="mr-2" />
					<h4 class="mt-2 Secondary">There is No Farm Available</h4>
				</div>
			</b-card>
		</div>
		<b-modal
			id="user_modal"
			title="Select User"
			:no-close-on-backdrop="true"
			@ok="getData"
			@hidden="handleModalClose"
			ref="userModal"
		>
			<b-form-group>
				<template v-slot:label>User<span class="text-danger">*</span> </template>
				<div class="form-label-group">
					<validation-provider #default="{ errors }" name=" Select User" rules="required">
						<v-select v-model="formData.user_id" :options="users" label="name" clearable:false :reduce="(e) => e.id" />
						<small class="text-danger">{{ errors[0] }}</small>
					</validation-provider>
				</div>
			</b-form-group>
		</b-modal>
	</div>
</template>

<script>
import {
	BCard,
	BCardHeader,
	BCardTitle,
	BCardText,
	BCardBody,
	BRow,
	BCol,
	BMedia,
	BMediaAside,
	BAvatar,
	BMediaBody,
	BCarousel,
	BCarouselSlide,
	BButton,
	BImg,
	BFormDatepicker,
	BFormGroup,
} from "bootstrap-vue";

import vSelect from "vue-select";
import axiosIns from "@/libs/axios";
import moment from "moment";
export default {
	components: {
		BRow,
		BCol,
		BCard,
		BCardHeader,
		BCardTitle,
		BCardText,
		BCardBody,
		BMedia,
		BAvatar,
		BMediaAside,
		BMediaBody,
		BButton,
		BFormDatepicker,
		BCarousel,
		BCarouselSlide,
		BImg,
		BFormGroup,
		vSelect,
	},
	data() {
		return {
			infoData: [],
			users: [],
			info: {},
			shedCount: null,
			search_filter: {
				input_field: "",
				to_date: "",
				from_date: "",
			},
			from_date: null,
			to_date: null,
			formData: {
				user_id: null,
			},
			farmId: null,
		};
	},

	created() {
		this.fromDate = moment().clone().startOf("month").format("YYYY-MM-DD");
		this.toDate = moment().clone().endOf("month").format("YYYY-MM-DD");
		this.getAllUser();
		this.farmId = this.$route.params.farmId;
		this.getData();
	},
	methods: {
		goBack() {
			this.$router.go(-1);
		},
		getAllUser() {
			axiosIns
				.get(`web/users`)
				.then((response) => {
					this.users = response.data.users;
				})
				.catch((error) => {
					const data = error.response.data.message;
					this.$toast({
						component: ToastificationContent,
						position: "top-right",
						props: {
							icon: "CoffeeIcon",
							variant: "danger",
							text: data,
						},
					});
				});
		},
		getData() {
			let url = "";
			let userId = this.formData.user_id;
			let farmId = this.farmId;
			if (farmId === undefined) {
				farmId = null;
			}
			if (this.from_date != null && this.to_date != null) {
				url = `web/breeder-details?from_date=${this.from_date}&to_date=${this.to_date}&user_id=${userId}&farmId=${farmId}`;
			} else {
				url = `web/breeder-details?from_date=${this.fromDate}&to_date=${this.toDate}&user_id=${userId}&farmId=${farmId}`;
			}

			axiosIns
				.get(url)
				.then((response) => {
					this.infoData = response.data.farm_details;
				})
				.catch((error) => {
					this.$refs.setErrors(error.response.data.error);
				});
		},
		shedInfo(shedId) {
			let farmId = this.$route.params.farmId;
			if (farmId) {
				this.$router.push({
					path: `/apps/manage-farm/${farmId}/dashboard/breeder/shed/${shedId}`,
				});
			} else {
				this.$router.push({
					name: `dashboard-breeder-shed`,
					params: { shedId: `${shedId}` },
				});
			}
		},
		handleCheckboxClick() {
			const checkbox = document.querySelector(".user-check");
			const modal = this.$refs.userModal;
			if (checkbox.checked) {
				modal.show();
			} else {
				this.formData.user_id = null;
				this.getData();
			}
		},
		handleModalClose() {
			if (this.formData.user_id == null) {
				const checkbox = document.querySelector(".user-check");
				checkbox.checked = false;
			}
		},
	},
};
</script>
<style lang="scss">
.show-user {
	margin: 0rem 0.5rem;
}
.shed {
	align-items: center !important;
}
.carousel-inner {
	height: 200px !important;
}
.top-header {
	border-bottom: 1px solid #296db4;
	padding: 1rem 0rem;
}
.switch {
	position: relative;
	display: inline-block;
	width: 50px;
	height: 25px;
}

.switch input {
	opacity: 0;
	width: 0;
	height: 0;
}

.slider {
	position: absolute;
	cursor: pointer;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	background-color: #ccc;
	-webkit-transition: 0.4s;
	transition: 0.4s;
}

.slider:before {
	position: absolute;
	content: "";
	height: 18px;
	width: 20px;
	left: 2px;
	bottom: 4px;
	background-color: white;
	-webkit-transition: 0.4s;
	transition: 0.4s;
}

input:checked + .slider {
	background-color: #2196f3;
}

input:focus + .slider {
	box-shadow: 0 0 1px #2196f3;
}

input:checked + .slider:before {
	-webkit-transform: translateX(26px);
	-ms-transform: translateX(26px);
	transform: translateX(26px);
}

/* Rounded sliders */
.slider.round {
	border-radius: 34px;
}

.slider.round:before {
	border-radius: 50%;
}

.shed-name:hover {
	cursor: pointer;
}
@import "@core/scss/vue/libs/vue-select.scss";
@import "@core/scss/vue/pages/hatchery.scss";
@import "@core/scss/vue/pages/_common.scss";
</style>
